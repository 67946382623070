export default function () {
  return {
    disabled: false,
    isAddToCartAction: false,
    qty: 1,

    initObservers: {
      ['@updateQuantity.window']: function ($event) {
        const data = $event.detail;
        if (!data) {
          return;
        }
        this['qty'] = data.quantity;
      },
      ['@addProductToCart.window']: function ($event) {
        // @ts-ignore;
        this.addToCart($event);
      },
      ['@add-to-cart-start.window']: function ($event) {
        // @ts-ignore;
        this.disabled = true;
      },
      ['@add-to-cart-finish.window']: function ($event) {
        // @ts-ignore;
        setTimeout(() => {
          // @ts-ignore;
          this.disabled = false;
        }, 200);
      },
      ['@private-data-loaded.window']: function ($event) {

        // @ts-ignore
        if (this.isAddToCartAction) {

          // @ts-ignore
          this.isAddToCartAction = false;

          /*
          window.dispatchEvent(new CustomEvent("messages-loaded", {
              detail: {
                  messages: [
                      {
                          'type': 'success',
                          // @ts-ignore
                          'text': this.$el.getAttribute('data-addtocart-success')
                      }
                  ]
              }
          }));
          */
        }
      }
    },
    init: function ($refs, data) {
      if (data.qty > 0) {
        this.qty = data.qty;
      }
    },

    onQtyChange: function () {

      var event = new CustomEvent('updateQuantity', {
        detail: {
          quantity: this.qty
        }
      });

      window.dispatchEvent(event);
    },

    incQuantity: function () {

      this.qty++;
      this.onQtyChange();
    },

    decQuantity: function () {

      if (this.qty > 1) {
        this.qty--;
        this.onQtyChange();
      }
    },

    addToCart: function ($event) {
      this.disabled = true;
      window.dispatchEvent(new CustomEvent("add-to-cart-start", {}));
      $event.preventDefault();
      // @ts-ignore
      var form = document.getElementById('product_addtocart_form');
      if(!form) return;
      var url = form.getAttribute('action');

      // @ts-ignore
      fetch(url, {
        method: 'post',
        headers: {
          Accept: 'application/json',
        },
        // @ts-ignore
        body: new FormData(form)
      }).then(response => {
        this.disabled = false;

        if (response.ok) {
          response.json().then(data => {
            // @ts-ignore
            this.errorMessage = ''; //
            var status = data.status;
            if (status == 1) {
              window.dispatchEvent(new CustomEvent("add-to-cart-finish", {}));
              window.dispatchEvent(new CustomEvent("add-to-cart-successful", {}));
              // @ts-ignore
              this.isAddToCartAction = true;
              window.dispatchEvent(new CustomEvent("reload-private-data", {}));
            } else {
              // @ts-ignore
              this.errorMessage = data.message || "Product not available1";
              window.dispatchEvent(new CustomEvent("messages-loaded", {
                detail: {
                  messages: [
                    {
                      'type': 'danger',
                      // @ts-ignore
                      'text':    this.errorMessage
                    }
                  ]
                }
              }));
            }
          });
        }
      })
    }
  }
}
