export default function () {
  return {
    disabled: false,
    isAddToCartAction: false,
    success: false,
    qty: 1,
    addToCart: function (url) {
      this.disabled = true;
      this.success = false;
      // @ts-ignore
      this.errorMessage = ''; //

      // @ts-ignore
      fetch(url+'?form_key='+window.FORM_KEY, {
        method: 'post',
        headers: {
          Accept: 'application/json',
        }
      }).then(response => {
        this.disabled = false;
        this.success = true;
        if (response.ok) {
          response.json().then(data => {
            var status = data.status;
            if (status == 1) {
              window.dispatchEvent(new CustomEvent("reload-private-data", {}));
            }else {
              // @ts-ignore
              this.errorMessage = data.message || "Product not available";
              // @ts-ignore
              this.$refs.form.setAttribute('data-addtocart-failed', this.errorMessage); // Set error message dynamically

            }
          });
        }
      })
    }
  }
}
